@media screen and (min-width: 680px) {
  .project-description {
    height: calc(100vw / 5);
    max-height: 120px;
  }
}

@media screen and (min-width: 1200px) {
  .project-description {
    height: calc(100vw / 4);
    max-height: 120px;
  }
}

.project-heading {
  font-size: 1.2rem;
}

.project-image-container {
  display: flex;
  justify-content: center;
}

.project-image {
  max-width: min(650px, 100%);
  margin-bottom: 25px;
}

.project-description {
  color: var(--sharp-grey);
  font-size: 1.2rem;
  margin-bottom: 25px;
}

.project-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 55px;
}

.project-buttons a {
  text-decoration: none;
}
